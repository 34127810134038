<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">평가기획마법사</h1>
    <div class="box-wrap ">
      <div class="box-ct d-flex">
        <div>
          <div class="form-inp sm">
            <v-select
              :items="$store.getters['userStore/GE_USER_COMPANY']"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="회사구분"
              placeholder="선택하세요"
              v-model="grid01Search.ASP_NEWCUST_KEY"
              @change="selectRtnQa()"
              :readonly="RDOnly"
            >
            <template v-slot:label>
                회사구분
              <v-icon color="#EF9191">mdi-circle-small</v-icon>
            </template>
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              :items="mixin_common_code_get(allCodeList,'PLT008','전체')"
              item-text="text"
              item-value="value"
              outlined
              hide-details
              label="유형타입"
              placeholder="선택하세요"
              v-model="grid01Search.QA_TY"
              @change="selectRtnQa()"
            >
            </v-select>
          </div>
          <v-text-field
            class="form-inp ml-2"
            name="name" label="QA평가명" id="id" outlined hide-details=""
            v-model="grid01Search.QA_NM"
            @keyup.enter="selectRtnQa()"
            >
          </v-text-field>
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            content-class="calendar-modal"
            color="#FFF"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="searchDateRangeText"
                v-bind="attrs"
                v-on="on"
                readonly
                outlined
                hide-details
                class="form-inp icon-calendar ml-2"
                append-icon="svg-calendar"
                label="전체평가기간"
              ></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker
                v-model="grid01Search.START_DATE"
                no-title
                dark
                color="#F7AFAE"
                @change="selectRtnQa()"
                locale="ko-KR"
                :first-day-of-week="1"
                :day-format="mixin_getDate"
              ></v-date-picker>
              <v-date-picker
                v-model="grid01Search.END_DATE"
                dark
                color="#F7AFAE"
                no-title
                show-current="false"
                :min="grid01Search.START_DATE"
                @change="selectRtnQa()"
                locale="ko-KR"
                :first-day-of-week="1"
                :day-format="mixin_getDate"
              ></v-date-picker>
            </div>
            <div class="text-center pb-3">
              <v-btn outlined class="btn-default" @click="menu1 = false">확인</v-btn>
            </div>
          </v-menu>
        </div>
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch1')" outlined class="btn-etc2" @click="selectRtnQa()"> 조회 </v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnMody')" outlined class="btn-point ml-2" :disabled="!isSelected || !isModify" @click="updateRtnQaForm()"> 수정 </v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnRegi')" outlined class="btn-point ml-2" @click="insertRtnQaForm()"> 등록 </v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')" outlined class="btn-etc ml-2" :disabled="!isSelected || !isModify" @click="grid01DeleteAlert()"> 삭제 </v-btn>
        </div>
        <v-dialog max-width="655" persistent v-model="dialogM310201P01" v-if="dialogM310201P01"
          content-class="square-modal min-auto"
        >
          <dialog-M310201P01
            p01
            headerTitle="평가 기획"
            :isModify="mFlag"
            :ASP_NEWCUST_KEY="grid01Search.ASP_NEWCUST_KEY"
            :PLT008="mixin_common_code_get(allCodeList,'PLT008','선택')"
            @hide="hideDialog('M310201P01')"
            @submit="insertRtnQaCallBack()"
            :grid01Selected="mFlag? grid01Selected: {}"
          >
          </dialog-M310201P01>
        </v-dialog>
      </div>
    </div>
    <!-- 평가 기획 목록 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">평가 기획 목록</h2>
      <div class="box-ct">
        <v-data-table
          dense
          height="366px"
          :headers="grid01Headers"
          :items="grid01Data"
          item-key="QA_ID"
          hide-default-footer
          class="grid-default"
          @click:row="setGrid01Select"
          :no-data-text="noDataTable"
          :item-class="isActiveRow"
          :items-per-page="30"
          fixed-header
        >
        <template v-slot:item.QA_TA_ST_NM="{item}">
          <template v-if="item.QA_TA_ST == '10'">기획</template>
          <template v-else-if="item.QA_TA_ST == '99'">완료</template>
          <template v-else>실행</template>
        </template>
        </v-data-table>
      </div>
    </div>
    <!-- 탭 -->
    <div class="box-wrap">
      <!-- 버튼 -->
      <div class="absolute-left-menu">
        <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnFin')" outlined class="btn-etc ml-2" :disabled="!isSelected || grid01Selected.QA_TA_ST == '99'" @click="common_confirm('기획을 종료하시겠습니까?', updateRtnEndQa,'',null,'','chk')"> 종료 </v-btn>
        <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined class="btn-point ml-2" v-show="activeTab != 3" :disabled="activeTab == 0? !isSelected || grid01Selected.QA_TA_ST == '99' :(!isSelected || !isModify) || grid01Selected.QA_TA_ST == '99'" @click="common_confirm('저장하시겠습니까?', processRtn,'', null,'', 'chk')"> 저장 </v-btn>
      </div>
      <v-tabs
        class="tabs-form"
        slider-color="#6C6E7A"
        slider-size="3"
        v-model="activeTab"
      >
        <v-tab>평가 기획 정보</v-tab>
        <v-tab>차수 설정</v-tab>
        <v-tab>대상자 발췌</v-tab>
        <v-tab>QA 할당</v-tab>

        <!-- 평가 기획 정보 -->
        <v-tab-item>
          <div class="box-ct mt-2">
            <div>
              <div class="form-inp sm">
                <v-text-field
                class="form-inp"
                name="name" label="유형타입" id="id" outlined hide-details="" disabled :value="grid01Selected.QA_TY_NM">

              </v-text-field>
              </div>
              <v-text-field
                class="form-inp ml-2"
                name="name" label="QA평가명" id="id" outlined hide-details="" disabled :value="grid01Selected.QA_NM">

              </v-text-field>
              <v-text-field
                class="form-inp ml-2"
                name="name" label="기획자명" id="id" outlined hide-details="" disabled :value="grid01Selected.QA_TG_PL_NM">
              </v-text-field>
              <v-text-field
                class="form-inp sm ml-2"
                name="name" label="기획자ID" id="id" outlined hide-details="" disabled :value="grid01Selected.QA_TG_PL_ID">
              </v-text-field>
              <v-text-field
                  outlined
                  hide-details
                  class="form-inp icon-calendar ml-2"
                  append-icon="svg-calendar"
                  label="평가기간"
                  disabled
                  :value="grid01Selected.QA_ST_DTTM != null? grid01Selected.QA_ST_DTTM + ' ~ ' + grid01Selected.QA_EN_DTTM : ''"
              ></v-text-field>
              <div class="form-inp ml-2">
                <v-select
                  :items="evlPaperSelectBox"
                  item-text="QA_EVA_NM"
                  item-value="QA_EVA_ID"
                  outlined
                  hide-details
                  label="평가표명"
                  placeholder="선택하세요"
                  :disabled="(!isSelected || !isModify)"
                  v-model="tab01.QA_EVA_ID"
                  @change="changeEvlPaper"
                >
                </v-select>
              </div>
              <!-- 조회 버튼 -->
              <v-btn 
                v-if="this.mixin_set_btn(this.$options.name, 'btnSearch2')"
                dark small
                class="btn-main-search pa-0 ml-2" min-width="32px" height="32px" plain
                :disabled="(!isSelected || !isModify)" @click="showDialog('M310201P02')">
                <v-icon small class="svg-main-searh white"></v-icon>
              </v-btn>

              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnPreview')" outlined class="btn-default ml-2" :disabled="!isSelected || this.tab01.QA_EVA_ID == ''" @click="selectRtnQaPreView()">미리보기</v-btn>
              <v-dialog
                max-width="1400"
                max-height="600"
                persistent
                v-model="dialogM310101P04"
                content-class="square-modal modal-white"
              >
                <dialog-M310101P01
                  headerTitle="미리보기"
                  p04
                  :preEvlRstData="preViewQaQs"
                  :preViewList="preViewVe"
                  @hide="hideDialog('M310101P04')"
                >
                </dialog-M310101P01>
              </v-dialog>
              <v-dialog max-width="800" persistent v-model="dialogM310201P02" v-if="dialogM310201P02"
                content-class="square-modal modal-white">
                <dialog-M310201P01
                  p02
                  headerTitle="평가표 검색"
                  mFlag
                  :ASP_NEWCUST_KEY="grid01Search.ASP_NEWCUST_KEY"
                  :PLT008="mixin_common_code_get(allCodeList,'PLT008','전체')"
                  :PLT011="mixin_common_code_get(allCodeList,'PLT011','전체')"
                  :PLT012="mixin_common_code_get(allCodeList,'PLT012','전체')"
                  @hide="hideDialog('M310201P02')"
                  @selected="selectEvlPaper"
                ></dialog-M310201P01>
              </v-dialog>
            </div>
            <div class="mt-2">
              <div class="form-inp sm">
                <v-select
                  :items="mixin_common_code_get(allCodeList,'PLT014','선택')"
                  item-text="text"
                  item-value="value"
                  outlined
                  hide-details
                  label="평가대상구분"
                  placeholder="선택하세요"
                  :disabled="(!isSelected || !isModify)"
                  v-model="tab01.QA_TA_DV"
                >
                </v-select>
              </div>
              <v-text-field
                class="form-inp sm ml-2"
                name="name" label="목표점수" type="number" id="id" outlined hide-details="" min="0" :disabled="(!isSelected || !isModify)" v-model="tab01.QA_TA_SC">
              </v-text-field>
              <v-text-field
                class="form-inp sm ml-2"
                name="name" label="진행상태" id="id" outlined hide-details="" disabled :value="grid01Selected.QA_TA_ST_NM">
              </v-text-field>
              <div class="chk-grp ml-2">
                <span class="in-label">평가실행완료여부</span>
                <v-checkbox class="d-inline-flex pr-4" :on-icon="'svg-chk-on'" :off-icon="'svg-chk-off'" hide-details
                  label="1차완료"
                  true-value="25"
                  false-value=""
                  v-model="QA_TA_ST[0]"
                  :disabled="grid01Selected.QA_TA_ST != '20' || !isSelected ? true:false"

                ></v-checkbox>
                <v-checkbox class="d-inline-flex pr-4" :on-icon="'svg-chk-on'" :off-icon="'svg-chk-off'" hide-details
                  label="2차완료"
                  true-value="35"
                  false-value=""
                  v-model="QA_TA_ST[1]"
                  :disabled="grid01Selected.QA_TA_ST != '30' || !isSelected || grid01Selected.QA_EVA_CN < 2 ? true:false"
                ></v-checkbox>
                <v-checkbox class="d-inline-flex" :on-icon="'svg-chk-on'" :off-icon="'svg-chk-off'" hide-details
                  label="3차완료"
                  true-value="45"
                  false-value=""
                  v-model="QA_TA_ST[2]"
                  :disabled="grid01Selected.QA_TA_ST != '40' || !isSelected || grid01Selected.QA_EVA_CN < 3 ? true:false"
                ></v-checkbox>
              </div>
              <v-text-field
                class="form-inp sm ml-2"
                name="name" label="이의제기여부" id="id" outlined hide-details="" disabled>
              </v-text-field>
              <v-text-field
                class="form-inp sm ml-2"
                name="name" label="이의제기가능" id="id" outlined hide-details="" disabled v-model="tab01.QA_EVA_RE_YN">
              </v-text-field>
            </div>
            <div class="mt-2">
              <h3 class="tit-h3">기타</h3>
              <!-- <div class="form-inp">
                <v-select
                  :items="dropItems"
                  item-text="title"
                  outlined
                  hide-details
                  label="평가진행중 상담사추가"
                  placeholder="선택하세요"
                >
                </v-select>
              </div> -->
              <div class="form-inp">
                <v-select
                  :items="mixin_common_code_get(allCodeList,'PLT015','선택')"
                  item-text="text"
                  item-value="value"
                  outlined
                  hide-details
                  label="평가진행중 콜추가"
                  placeholder="선택하세요"
                  :disabled="(!isSelected || !isModify)"
                  v-model="tab01.QA_PG_AD_CN_YN"
                >
                </v-select>
              </div>
              <v-text-field
                class="form-inp ml-2"
                name="name" label="평가기획 수정가능" id="id" outlined hide-details disabled :value="tab01.MODIFY_YN">
              </v-text-field>
            </div>
          </div>
        </v-tab-item>
        <!-- 차수 설정 -->
        <v-tab-item>
          <div class="box-ct mt-2">
            <div class="d-inline-block">
              <h3 class="tit-h3">1차 평가</h3>
              <v-menu
                v-model="menu3"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                content-class="calendar-modal"
                color="#FFF"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="qaFsRangeText"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    outlined
                    hide-details
                    class="form-inp icon-calendar"
                    append-icon="svg-calendar"
                    label="전체평가기간"
                    :disabled="(!isSelected || !isModify)"
                  ></v-text-field>
                </template>
                <div class="datepicker-range">
                  <v-date-picker
                    v-model="tab02.QA_FS_ST_DTTM"
                    no-title
                    dark
                    color="#F7AFAE"
                    locale="ko-KR"
                    :first-day-of-week="1"
                    :day-format="mixin_getDate"
                  ></v-date-picker>
                  <v-date-picker
                    v-model="tab02.QA_FS_EN_DTTM"
                    dark
                    color="#F7AFAE"
                    no-title
                    :min="TODAY"
                    locale="ko-KR"
                    :first-day-of-week="1"
                    :day-format="mixin_getDate"
                  ></v-date-picker>
                </div>
                <div class="text-center pt-3 pb-3">
                  <v-btn
                    outlined
                    class="btn-default"
                    @click="menu3 = false"
                    >확인</v-btn
                  >
                </div>
              </v-menu>
            </div>
            <div class="d-inline-block ml-3">
              <h3 class="tit-h3">2차 평가</h3>
              <v-menu
                v-model="menu4"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                content-class="calendar-modal"
                color="#FFF"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="qaScRangeText"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    outlined
                    hide-details
                    class="form-inp icon-calendar"
                    append-icon="svg-calendar"
                    label="전체평가기간"
                    :disabled="(!isSelected || !isModify)"
                  ></v-text-field>
                </template>
                <div class="datepicker-range">
                  <v-date-picker
                    v-model="tab02.QA_SC_ST_DTTM"
                    no-title
                    dark
                    color="#F7AFAE"
                    locale="ko-KR"
                    :first-day-of-week="1"
                    :day-format="mixin_getDate"
                  ></v-date-picker>
                  <v-date-picker
                    v-model="tab02.QA_SC_EN_DTTM"
                    dark
                    color="#F7AFAE"
                    no-title
                    :min="TODAY"
                    locale="ko-KR"
                    :first-day-of-week="1"
                    :day-format="mixin_getDate"
                  ></v-date-picker>
                </div>
                <div class="text-center pt-3 pb-3">
                  <v-btn
                    outlined
                    class="btn-default"
                    @click="menu4 = false"
                    >확인</v-btn
                  >
                </div>
              </v-menu>
              <div class="form-inp ml-2">
                <v-select
                  :items="dropItems"
                  item-text="title"
                  outlined
                  hide-details
                  label="2차 평가 시작 시 1차 평가값 복사"
                  placeholder="선택하세요"
                  v-model="tab02.QA_FS_CP_YN"
                  :disabled="(!isSelected || !isModify)"
                >
                </v-select>
              </div>
            </div>
            <div class="d-inline-block ml-3">
              <h3 class="tit-h3">3차 평가</h3>
              <v-menu
                v-model="menu5"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                content-class="calendar-modal"
                color="#FFF"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="qaFnRangeText"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    outlined
                    hide-details
                    class="form-inp icon-calendar"
                    append-icon="svg-calendar"
                    label="전체평가기간"
                    :disabled="(!isSelected || !isModify)"
                  ></v-text-field>
                </template>
                <div class="datepicker-range">
                  <v-date-picker
                    v-model="tab02.QA_FN_ST_DTTM"
                    no-title
                    dark
                    color="#F7AFAE"
                    locale="ko-KR"
                    :first-day-of-week="1"
                    :day-format="mixin_getDate"
                  ></v-date-picker>
                  <v-date-picker
                    v-model="tab02.QA_FN_EN_DTTM"
                    dark
                    color="#F7AFAE"
                    no-title
                    :min="TODAY"
                    locale="ko-KR"
                    :first-day-of-week="1"
                    :day-format="mixin_getDate"
                  ></v-date-picker>
                </div>
                <div class="text-center pt-3 pb-3">
                  <v-btn
                    outlined
                    class="btn-default"
                    @click="menu5 = false"
                    >확인</v-btn
                  >
                </div>
              </v-menu>
              <div class="form-inp ml-2">
                <v-select
                  :items="dropItems"
                  item-text="title"
                  outlined
                  hide-details
                  label="3차 평가 시작 시 2차 평가값 복사"
                  placeholder="선택하세요"
                  v-model="tab02.QA_SC_CP_YN"
                  :disabled="(!isSelected || !isModify)"
                >
                </v-select>
              </div>
            </div>
          </div>
        </v-tab-item>
        <!-- 대상자 발췌 -->
        <v-tab-item>
          <div class="box-ct mt-2">
            <h3 class="tit-h3">대상 조건</h3>
            <div class="d-flex">
              <div>
                <div>
                  <v-menu
                    v-model="menu6"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    color="#FFF"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="cnslDateRangeText"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        outlined
                        hide-details
                        class="form-inp icon-calendar"
                        append-icon="svg-calendar"
                        label="상담 일자"
                      >
                      <template v-slot:label>
                          상담 일자
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                      </v-text-field>
                    </template>
                    <div class="datepicker-range">
                      <v-date-picker
                        v-model="tab03.QA_TG_ST_DTTM"
                        no-title
                        dark
                        color="#F7AFAE"
                        :max="toDay"
                        :disabled="tab03Modify"
                        locale="ko-KR"
                        :first-day-of-week="1"
                        :day-format="mixin_getDate"
                      ></v-date-picker>
                      <v-date-picker
                        v-model="tab03.QA_TG_EN_DTTM"
                        dark
                        color="#F7AFAE"
                        no-title
                        :min="tab03.QA_TG_ST_DTTM"
                        :max="toDay"
                        :disabled="tab03Modify"
                        locale="ko-KR"
                        :first-day-of-week="1"
                        :day-format="mixin_getDate"
                      ></v-date-picker>
                    </div>
                    <div class="text-center pt-3 pb-3">
                      <v-btn
                          outlined
                          class="btn-default"
                          @click="menu6 = false"
                      >확인</v-btn
                      >
                    </div>
                  </v-menu>
                  <v-text-field
                    class="form-inp sm ml-2"
                    name="name" label="근속기간"
                    suffix="개월"
                    outlined hide-details
                    type="number"
                    min="0"
                    v-model="tab03.QA_TG_CR_ST"
                    :disabled="tab03Modify"
                    >
                  </v-text-field>
                  <span> ~</span>
                  <v-text-field
                    class="form-inp sm ml-2"
                    name="name" label="근속기간"
                    suffix="개월"
                    outlined hide-details
                    type="number"
                    min="0"
                    v-model="tab03.QA_TG_CR_EN"
                    :disabled="tab03Modify"
                    >
                  </v-text-field>
                  <v-text-field
                    class="form-inp sm ml-2"
                    name="name" label="통화시간"
                    suffix="분 이상"
                    outlined hide-details
                    type="number"
                    min="0"
                    v-model="tab03.QA_TG_CNSL_TIME"
                    :disabled="tab03Modify"
                    >
                  </v-text-field>
                  <div class="form-inp sm ml-2">
                    <v-select
                      :items="mixin_common_code_get(allCodeList,'PLT016','선택')"
                      item-text="text"
                      item-value="value"
                      outlined
                      hide-details
                      label="유형"
                      placeholder="선택하세요"
                      v-model="tab03.QA_TG_TY"
                      @change="changeQaTgTy()"
                      :disabled="tab03Modify"
                    >
                    <template v-slot:label>
                          유형
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                    </v-select>
                  </div>
                  <div class="form-inp ml-2 sm">
                    <v-select
                      :items="gridSelectBox.QA_CNSL_TYP_CD"
                      item-text="CD_NM"
                      item-value="CD"
                      outlined
                      hide-details
                      label="상담유형"
                      placeholder="선택하세요"
                      v-model="tab03.QA_CNSL_TYP_CD"
                      @change="selectRtnCnslType(tab03.QA_CNSL_TYP_CD, 'QA_CNSL_TYP_CD2')"
                      no-data-text="전체"
                      :disabled="tab03Modify"
                    >
                    </v-select>
                  </div>
                  <div class="form-inp ml-2 sm">
                    <v-select
                      :items="gridSelectBox.QA_CNSL_TYP_CD2"
                      item-text="CD_NM"
                      item-value="CD"
                      outlined
                      hide-details
                      label="상담유형2"
                      placeholder="선택하세요"
                      v-model="tab03.QA_CNSL_TYP_CD2"
                      @change="selectRtnCnslType(tab03.QA_CNSL_TYP_CD2, 'QA_CNSL_TYP_CD3')"
                      no-data-text="전체"
                      :disabled="tab03Modify"
                    >
                    </v-select>
                  </div>
                  <div class="form-inp ml-2 sm">
                    <v-select
                      :items="gridSelectBox.QA_CNSL_TYP_CD3"
                      item-text="CD_NM"
                      item-value="CD"
                      outlined
                      hide-details
                      label="상담유형3"
                      placeholder="선택하세요"
                      v-model="tab03.QA_CNSL_TYP_CD3"
                      @change="selectRtnCnslType(tab03.QA_CNSL_TYP_CD3, 'QA_CNSL_TYP_CD4')"
                      no-data-text="전체"
                      :disabled="tab03Modify"
                    >
                    </v-select>
                  </div>
                  <div class="form-inp ml-2 sm">
                    <v-select
                      :items="gridSelectBox.QA_CNSL_TYP_CD4"
                      item-text="CD_NM"
                      item-value="CD"
                      outlined
                      hide-details
                      label="상담유형4"
                      placeholder="선택하세요"
                      v-model="tab03.QA_CNSL_TYP_CD4"
                      no-data-text="전체"
                      :disabled="tab03Modify"
                    >
                    </v-select>
                  </div>
                  <v-text-field
                    class="form-inp sm ml-2"
                    name="name" label="총건수"
                    outlined hide-details
                    readonly
                    reverse
                    v-model="tab03.QA_TG_CNT">
                  </v-text-field>
                </div>
              </div>
              <div class="ml-auto align-self-center">
                <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch3')" outlined class="btn-etc2" @click="selectRtnCnslHist(tab03.QA_TG_TY, 'S')">조회</v-btn>
              </div>

            </div>
            <!-- 그리드 -->
            <div class="mt-3" v-show="tab03.QA_TG_TY != '20'">
              <v-data-table
                dense
                :headers="grid02Headers"
                :items="grid02Data"
                item-key="index"
                hide-default-footer
                class="grid-default"
                height="200"
                :page.sync="grid02Paging.page"
                :items-per-page="30"
                @page-count="grid02Paging.pageCount = $event"
                :server-items-length="grid02Paging.totalCount"
                @update:page="selectRtnCnslHist(tab03.QA_TG_TY, '')"
                no-data-text="검색된 결과가 없습니다."
                fixed-header
              >
              </v-data-table>
              <div class="grid-paging text-center pt-2">
                <v-pagination
                  v-model="grid02Paging.page"
                  :length="grid02Paging.pageCount"
                  :total-visible="grid02Paging.totalVisible"
                  next-icon="svg-paging-next"
                  prev-icon="svg-paging-prev"
                ></v-pagination>
              </div>
            </div>
            <div class="mt-3" v-show="tab03.QA_TG_TY == '20'">
              <v-data-table
                dense
                :headers="grid03Headers"
                :items="grid03Data"
                item-key="index"
                hide-default-footer
                class="grid-default"
                height="200"
                :page.sync="grid03Paging.page"
                :items-per-page="30"
                @page-count="grid03Paging.pageCount = $event"
                :server-items-length="grid03Paging.totalCount"
                @update:page="selectRtnCnslHist(tab03.QA_TG_TY, '')"
                no-data-text="검색된 결과가 없습니다."
                fixed-header
              >
              </v-data-table>
              <div class="grid-paging text-center pt-2">
                <v-pagination
                  v-model="grid03Paging.page"
                  :length="grid03Paging.pageCount"
                  :total-visible="grid03Paging.totalVisible"
                  next-icon="svg-paging-next"
                  prev-icon="svg-paging-prev"
                ></v-pagination>
              </div>
            </div>
          </div>
        </v-tab-item>
        <!-- QA 할당 -->
        <v-tab-item>
          <div class="box-ct mt-2">
            <h3 class="tit-h3 d-flex">상세
              <div class="ml-auto">
                <v-text-field
                  class="form-inp sm "
                  name="name" label="총건수"
                  outlined hide-details
                  readonly
                  reverse
                  v-model="tab04.TOT_CNT"
                  >
                </v-text-field>
                <v-text-field
                  class="form-inp sm ml-2"
                  name="name" label="배분건수"
                  outlined hide-details
                  reverse
                  readonly
                  v-model="tab04.DIV_CNT"
                  >
                </v-text-field>
                <v-text-field
                  class="form-inp sm ml-2"
                  name="name" label="미배분건수"
                  outlined hide-details
                  reverse
                  readonly
                  v-model="tab04.NO_DIV_CNT"
                  >
                </v-text-field>
                <v-text-field
                  class="form-inp sm ml-2"
                  name="name" label="1차완료건수"
                  outlined hide-details
                  reverse
                  readonly
                  v-model="tab04.CP_FS_QA_CNT"
                  >
                </v-text-field>
                <v-text-field
                  class="form-inp sm ml-2"
                  name="name" label="2차완료건수"
                  outlined hide-details
                  reverse
                  readonly
                  v-model="tab04.CP_SC_QA_CNT"
                  >
                </v-text-field>
                <v-text-field
                  class="form-inp sm ml-2"
                  name="name" label="3차완료건수"
                  outlined hide-details
                  reverse
                  readonly
                  v-model="tab04.CP_FN_QA_CNT"
                  >
                </v-text-field>
                <v-text-field
                  class="form-inp sm ml-2"
                  name="name" label="완료건수"
                  outlined hide-details
                  reverse
                  readonly
                  v-model="tab04.CP_QA_CNT"
                  >
                </v-text-field>
              </div>
            </h3>
            <!-- 그리드 -->
            <div class="mt-3">
              <v-data-table
                dense
                :headers="grid04Headers"
                :items="grid04Data"
                item-key="DIV_USER_ID"
                hide-default-footer
                class="grid-default"
                height="150"
                show-select
                v-model="grid04Selected"
                no-data-text="검색된 결과가 없습니다."
                fixed-header
              >
                <template v-slot:item.QA_DATA = "{item}">
                  <v-text-field
                    class="form-inp sm ml-2"
                    name="name"
                    outlined hide-details
                    v-model="item.QA_DATA"
                    type="number"
                    min="0"
                  >
                  </v-text-field>
                </template>
              </v-data-table>
            </div>
            <!-- 버튼 -->
            <div class="mt-3 justify-end d-flex">
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnInitQA')" outlined class="btn-default" :disabled="!isSelected || grid01Selected.QA_TA_ST == '99'" @click="setQaDivReSet()">초기화</v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAllBack')" outlined class="btn-point ml-2" :disabled="!isSelected || grid01Selected.QA_TA_ST == '99'" @click="setQaDivRecallAll()">전체회수</v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnBack')" outlined class="btn-default ml-2" :disabled="!isSelected || grid01Selected.QA_TA_ST == '99'" @click="common_confirm('회수하시겠습니까?',processRtnQaDivRecall,'',null,'','chk')">회수</v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSameDiv')" outlined class="btn-point ml-2" :disabled="!isSelected || grid01Selected.QA_TA_ST == '99'" @click="setQaDivAvg()">균등분배</v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDiv')" outlined class="btn-default ml-2" :disabled="!isSelected || grid01Selected.QA_TA_ST == '99'" @click="common_confirm('배분하시겠습니까?',processRtnQaDiv,'',null,'','chk')">배분</v-btn>
            </div>
          </div>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import DialogM310201P01 from "./M310201P01.vue";
import DialogM310101P01 from './M310101P01.vue'
import api from '@/store/apiUtil.js'
import {mixin} from '@/mixin/mixin.js'
export default {
  name: "MENU_M310201", //name은 'MENU_' + 파일명 조합
  components: {
    DialogM310201P01,
    DialogM310101P01
  },
  mixins:[mixin], // mixin 등록
  data() {
    return {
      valid : true,
      valid03 : true,
      dialogM310201P01: false,
      dialogM310201P02: false,
      dialogM310201P03: false,
      dialogM310101P04: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      mFlag : false,
      activeTab : 0,
      QA_TA_ST : [],
      groupCdList : ['PLT008','PLT011','PLT012', 'PLT014', 'PLT015', 'PLT016','PLT017'],
      allCodeList : [],
      noDataTable:"검색된 결과가 없습니다.",
      aspCustKey : [{
        title : '한국클라우드'
        , value : 'HKCTALKMNG',
      }],
      dropItems: [
        { title: "예", value:"Y" }
        , {title: "아니오", value:"N"}
      ],
      chkTypes: [{
          text: '1차완료',
          selected: false,
          value : "25",
        },
        {
          text: '2차완료',
          selected: false,
          value : "35",
        },
        {
          text: '3차완료',
          selected: false,
          value : "45",
        },
      ],
      TODAY: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      grid01Headers:[
        {
          text: "No",
          align: "center",
          value: "QA_ID",
          width: "50px",
          sortable: true,
        },
        { text: "회사구분", value: "ASP_CUST_NM", align: "center", sortable: true },
        { text: "유형타입", value: "QA_TY_NM", align: "center", sortable: true },
        { text: "QA평가명", value: "QA_NM", align: "left", sortable: true },
        { text: "진행상태", value: "QA_TA_ST_NM", align: "center", sortable: true },
        { text: "시작일", value: "QA_ST_DTTM", align: "center", sortable: true },
        { text: "종료일", value: "QA_EN_DTTM", align: "center", sortable: true },
        { text: "대상 수량", value: "QA_TG_CNT", align: "center", sortable: true },
        { text: "할당 수량", value: "QA_DIV_CNT", align: "center", sortable: true },
        { text: "QA인원", value: "QA_USER_CNT", align: "center", sortable: true },

      ],
      grid01Data:[],
      grid01Selected: {},
      grid01Search:{
        ASP_NEWCUST_KEY : "",
        QA_TY : "",
        QA_NM : "",
        START_DATE : new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        END_DATE : new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
      evlPaperSelectBox : [],
      grid02Headers:[
        {text:"No", align:"center", value:"ROW_NUMBER",width:50, sortable: true,},
        {text:"상담일자", align:"center", value:"CNSL_DATE",width:120, sortable: true,},
        {text:"상담시작시간", align:"center", value:"CNSL_BEGIN_TIME", width:120, sortable: true,},
        {text:"통화시간", align:"center", value:"TEL_TIME", width:100, sortable: true,},
        {text:"콜유형", align:"center", value:"CALL_TY_NM", width:100, sortable: true,},
        {text:"콜기준여부", align:"center", value:"RDWT_SEND_YN", width:100, sortable: true,},
        {text:"통화자", align:"center", value:"PROC_CODE_SCLS_NM", width:100, sortable: true,},
        {text:"상담원명", align:"center", value:"CSLT_NM", width:100, sortable: true,},
        {text:"고객명", align:"center", value:"CUST_NM", width:100, sortable: true,},
        {text:"발신자번호", align:"center", value:"SEND_MAN_NO", width:100, sortable: true,},
        {text:"상담유형", align:"center", value:"CNSL_TYP_NM", width:100, sortable: true,},
        {text:"상담유형2", align:"center", value:"CNSL_TYP_NM_2", width:100, sortable: true,},
        {text:"상담유형3", align:"center", value:"CNSL_TYP_NM_3", width:100, sortable: true,},
        {text:"상담유형4", align:"center", value:"CNSL_TYP_NM_4", width:100, sortable: true,},
        {text:"완료여부", align:"center", value:"PROC_NM", width:100, sortable: true,},
        {text:"녹취파일명", align:"center", value:"RDWT_FILE_NM", width:100, sortable: true,},
        {text:"상담내용", align:"center", value:"CNSL_CNTN", width:100, sortable: true,},
      ],
      grid02Data:[],
      grid02Paging:{
        page: 1,
        pageCount: 10,
        itemsPerPage: 10,
        totalVisible: 10,
        totalCount : 0,
      },
      grid03Headers:[
        {text:"No", align:"center", value:"ROW_NUMBER",width:50, sortable: true,},
        {text:"메신저명", align:"center", value:"ASP_SENDER_KEY_NM", width:100, sortable: true,},
        {text:"회원/비회원", align:"center", value:"CUSTOMER_MEMBER", width:100, sortable: true,},
        {text:"고객ID", align:"center", value:"CUSTOMER_ID", width:100, sortable: true,},
        {text:"고객명", align:"center", value:"CUSTOMER_NM", width:100, sortable: true,},
        {text:"상담상태", align:"center", value:"TALK_STAT_NM", width:100, sortable: true,},
        {text:"문의유형", align:"center", value:"TALK_INQRY_NM_1", width:100, sortable: true,},
        {text:"문의유형2", align:"center", value:"TALK_INQRY_NM_2", width:100, sortable: true,},
        {text:"상담유형", align:"center", value:"CNSL_TYP_NM", width:100, sortable: true,},
        {text:"상담유형2", align:"center", value:"CNSL_TYP_NM_2", width:100, sortable: true,},
        {text:"상담유형3", align:"center", value:"CNSL_TYP_NM_3", width:100, sortable: true,},
        {text:"상담유형4", align:"center", value:"CNSL_TYP_NM_4", width:100, sortable: true,},
        {text:"상담제목", align:"center", value:"TALK_QST", width:100, sortable: true,},
        {text:"최초상담사", align:"center", value:"USER_ID_NM", width:100, sortable: true,},
        {text:"최종상담사", align:"center", value:"TRANS_USER_ID_NM", width:100, sortable: true,},
        {text:"상담요청일시", align:"center", value:"TALK_DIST_DT", width:100, sortable: true,},
        {text:"상담시작일시", align:"center", value:"TALK_START_DT", width:100, sortable: true,},
        {text:"상담종료일시", align:"center", value:"TALK_POST_DT", width:100, sortable: true,},
        {text:"상담완료일시", align:"center", value:"TALK_END_DT", width:100, sortable: true,},
        {text:"내용", align:"center", value:"CONTENT_VIEW", width:6, sortable: true,},
      ],
      grid03Data:[],
      grid03Paging:{
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
        totalVisible: 10,
        totalCount : 0,
      },
      gridSelectBox : {
        QA_CNSL_TYP_CD : [],
        QA_CNSL_TYP_CD2 : [],
        QA_CNSL_TYP_CD3 : [],
        QA_CNSL_TYP_CD4 : [],
      },
      tab01 : {
        QA_EVA_ID : "",
        QA_EVA_RE_YN : "",
        QA_TA_DV : "",
        QA_TA_SC : "",
        QA_PG_AD_US_YN : "",
        QA_PG_AD_CN_YN : "",
        QA_ID : "",
        ASP_NEWCUST_KEY : "",
        REG_ID : "",
      },
      preViewQaQs : [],
      preViewVe : [],
      tab02 : {
        QA_FS_ST_DTTM : "",
        QA_FS_EN_DTTM : "",
        QA_SC_ST_DTTM : "",
        QA_SC_EN_DTTM : "",
        QA_FN_ST_DTTM : "",
        QA_FN_EN_DTTM : "",
        QA_FS_CP_YN : "",
        QA_SC_CP_YN : "",
      },
      tab03 : {
        QA_TG_ST_DTTM : "",
        QA_TG_EN_DTTM : "",
      },
      tab04 : {
        TOT_CNT : "0",
        NO_DIV_CNT : "0",
        DIV_CNT : "0",
        CP_FS_QA_CNT : "0",
        CP_SC_QA_CNT : "0",
        CP_FN_QA_CNT : "0",
        CP_QA_CNT : "0",
      },
      grid04Headers:[
        {text:"No", align:"center", value:"ROW_NUMBER",width:50, sortable: true,},
        {text:"ID", align:"center", value:"DIV_USER_ID", width:100, sortable: true,},
        {text:"QA명", align:"center", value:"DIV_USER_NM", width:100, sortable: true,},
        {text:"배분", align:"center", value:"DIV_CNT", width:100, sortable: true,},
        {text:"완료", align:"center", value:"CP_QA_CNT", width:100, sortable: true,},
        {text:"미시도", align:"center", value:"WAIT_QA_CNT", width:100, sortable: true,},
        {text:"1차", align:"center", value:"CP_FS_QA_CNT", width:100, sortable: true,},
        {text:"2차", align:"center", value:"CP_SC_QA_CNT", width:100, sortable: true,},
        {text:"3차", align:"center", value:"CP_FN_QA_CNT", width:100, sortable: true,},
        {text:"추가/회수", align:"center", value:"QA_DATA", width:100, sortable: true,},
      ],
      grid04Data:[],
      grid04Selected:[],
      
      RDOnly:false,
    };
  },
  watch:{

  },
  created() {
    // this.getCmmCd(this.groupCdList,'','','');
  },
  async mounted(){
    this.allCodeList = await this.mixin_common_code_get_all(this.groupCdList);
  
    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.grid01Search.ASP_NEWCUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }
  },
  methods: {
    /*===========================데이터 조회 시작 ============================*/
    //평가 기획 조회
    async selectRtnQa(){
      if(this.grid01Search.ASP_NEWCUST_KEY == undefined || this.grid01Search.ASP_NEWCUST_KEY == ''){
        this.common_alert("회사구분을 선택해주세요.","error");
      }else{
        let URLData = "api/phone/plan/qa-manage/list";
        let HEADER_SERVICE = "phone.plan.qa-manage";
        let HEADER_METHOD = "list";
        let HEADER_TYPE = "BIZ_SERVICE";

        await api.post(URLData, this.grid01Search, {
          head : {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
          }
        })
        .then((response) => {
          this.grid01Data = response.data.DATA;
        })
        .catch((err) => {
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
        })
      }
    },

    //평가표 selectbox 조회
    async selectRtnEvlPaper(){
      let URLData = "api/phone/plan/qa-manage/evl-paper/list";
      let HEADER_SERVICE = "phone.plan.qa-manage.evl-paper";
      let HEADER_METHOD = "list";
      let HEADER_TYPE = "BIZ_SERVICE";

      await api.post(URLData, this.grid01Search, {
        head : {
          SERVICE: HEADER_SERVICE,
          METHOD: HEADER_METHOD,
          TYPE: HEADER_TYPE,
        }
      })
      .then((response) => {
        this.evlPaperSelectBox = response.data.DATA;
      })
      .catch((err) => {
        this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
      })
    },

    grid01DeleteAlert(){
      if(this.isSelected){
        if(this.grid01Selected.QA_TA_ST == '10'){
          this.$store.commit("alertStore/openAlertDialog", {
            alertDialogToggle: true,
            msg: "정말 삭제하시겠습니까?",
            iconClass: "svg-error-lg",
            type: "confirm",
            callYes: () => {
              this.$store.commit("alertStore/openAlertDialog", {
                alertDialogToggle: false,
              });
              this.deleteRtnQa();
            },
            callNo: () => {
              this.$store.commit("alertStore/openAlertDialog", {
                alertDialogToggle: false,
              });
            },
          });
        }else{
          this.$store.commit("alertStore/openAlertDialog", {
            alertDialogToggle: true,
            msg: '진행상태가 기획중인 평가 기획만 삭제할 수 있습니다.',
            iconClass: 'svg-error-lg',
            type: 'default'
          })
        }
      }else{
        alert("선택안됨");
      }
    },
    async deleteRtnQa(){
      let URLData = "api/phone/plan/qa-manage/delete";
      let HEADER_SERVICE = "phone.plan.qa-manage";
      let HEADER_METHOD = "delete";
      let HEADER_TYPE = "BIZ_SERVICE";

      let jsonData = {
        "ASP_NEWCUST_KEY" : this.grid01Selected.ASP_NEWCUST_KEY,
        "QA_ID" : this.grid01Selected.QA_ID,
      }
      await api.post(URLData, jsonData, {
        head : {
          SERVICE: HEADER_SERVICE,
          METHOD: HEADER_METHOD,
          TYPE: HEADER_TYPE,
        }
      })
      .then((response) => {
        this.common_alert("정상처리 되었습니다.","done");
        this.grid01Selected = [];
        this.selectRtnQa();
      })
      .catch((err) => {
        this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
      })
    },
    //평가기획 종료
    async updateRtnEndQa(){
      let jsonData = {
        ASP_NEWCUST_KEY : this.grid01Search.ASP_NEWCUST_KEY,
        QA_ID : this.grid01Selected.QA_ID,
        QA_TA_ST : '99',
        REG_ID : this.$store.getters['userStore/GE_USER_ROLE'].userId,
      };

      let URLData = "api/phone/plan/qa-manage/detail/regist";
      let HEADER_SERVICE = "phone.plan.qa-manage.detail";
      let HEADER_METHOD = "regist";
      let HEADER_TYPE = "BIZ_SERVICE";

      await api.post(URLData, jsonData, {
        head : {
          SERVICE: HEADER_SERVICE,
          METHOD: HEADER_METHOD,
          TYPE: HEADER_TYPE,
        }
      })
      .then((response) => {
        this.selectRtnQa();
        this.common_alert("정상처리 되었습니다.","done");
      })
      .catch((err) => {
        this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
      })
    },

    async processRtn(){
      let jsonData = null;

      let URLData = "api/phone/plan/qa-manage/detail/regist";
      let HEADER_SERVICE = "phone.plan.qa-manage.detail";
      let HEADER_METHOD = "regist";
      let HEADER_TYPE = "BIZ_SERVICE";
      let userId = this.$store.getters['userStore/GE_USER_ROLE'].userId;
      let DATA_OBJECT = '';

      if(this.activeTab == 0){
        //평가 기획 정보
        let QA_TA_ST = this.grid01Selected.QA_TA_ST;
        for(let i = 0; i < 3; i++){
          if(QA_TA_ST < this.QA_TA_ST[i]){
            QA_TA_ST = this.QA_TA_ST[i];
          }
        }
        jsonData = {
          QA_ID : this.tab01.QA_ID,
          ASP_NEWCUST_KEY : this.tab01.ASP_NEWCUST_KEY,
          REG_ID : userId,
          QA_EVA_ID : this.tab01.QA_EVA_ID,
          QA_TA_DV : this.tab01.QA_TA_DV,
          QA_TA_SC : this.tab01.QA_TA_SC,
          QA_PG_AD_US_YN : this.tab01.QA_PG_AD_US_YN,
          QA_PG_AD_CN_YN : this.tab01.QA_PG_AD_CN_YN,
          STEP: '1',
          QA_TA_ST : QA_TA_ST,
        };

        console.log(JSON.stringify(jsonData));
      }else if(this.activeTab == 1){
        //차수 설정
        jsonData = {
          QA_ID : this.tab02.QA_ID,
          ASP_NEWCUST_KEY : this.tab02.ASP_NEWCUST_KEY,
          REG_ID : userId,
          QA_FS_ST_DTTM : this.tab02.QA_FS_ST_DTTM,
          QA_FS_EN_DTTM : this.tab02.QA_FS_EN_DTTM,
          QA_SC_ST_DTTM : this.tab02.QA_SC_ST_DTTM,
          QA_SC_EN_DTTM : this.tab02.QA_SC_EN_DTTM,
          QA_FN_ST_DTTM : this.tab02.QA_FN_ST_DTTM,
          QA_FN_EN_DTTM : this.tab02.QA_FN_EN_DTTM,
          QA_FS_CP_YN : this.tab02.QA_FS_CP_YN,
          QA_SC_CP_YN : this.tab02.QA_SC_CP_YN,
          STEP: '2',
        };
      }else if(this.activeTab == 2){
        //대상 발췌
        if(this.tab03.QA_TG_YN === 'Y' && this.tab03.QA_PG_AD_CN_YN != 10){
          this.common_alert("대상 발췌가 불가능한 상태입니다.","noti");
          return;
        }else{

          if(this.tab03.QA_TG_CNT == 0){
            this.common_alert("발췌할 대상이 없습니다. 대상 조건 수정 후 다시 진행하시기 바랍니다.","noti");
            return;
          }else if(this.tab03.QA_EVA_ID == null || this.tab03.QA_EVA_ID == ''){
            this.common_alert("평가기획정보를 저장 후 진행하기기 바랍니다.","noti");
            return;
          }else{
            jsonData = {
              QA_ID : this.tab03.QA_ID,
              ASP_NEWCUST_KEY : this.tab03.ASP_NEWCUST_KEY,
              REG_ID : userId,
              QA_EVA_ID : this.tab01.QA_EVA_ID,
              QA_TG_TY : this.tab03.QA_TG_TY,
              QA_TG_ST_DTTM : this.tab03.QA_TG_ST_DTTM,
              QA_TG_EN_DTTM : this.tab03.QA_TG_EN_DTTM,
              QA_TG_CR_ST : this.tab03.QA_TG_CR_ST,
              QA_TG_CR_EN : this.tab03.QA_TG_CR_EN,
              QA_TG_CNSL_TIME : this.tab03.QA_TG_CNSL_TIME,
              QA_CNSL_TYP_CD : this.tab03.QA_CNSL_TYP_CD,
              QA_CNSL_TYP_CD2 : this.tab03.QA_CNSL_TYP_CD2,
              QA_CNSL_TYP_CD3 : this.tab03.QA_CNSL_TYP_CD3,
              QA_CNSL_TYP_CD4 : this.tab03.QA_CNSL_TYP_CD4,
              QA_TG_CNT : this.tab03.QA_TG_CNT,
              STEP: '3',
            };
          }
        }
      }else if(this.activeTab == 3){
        //QA 할당
        DATA_OBJECT = 'DIV_LIST'
        jsonData = {
          STEP : '4',
          QA_ID : this.tab04.QA_ID,
          ASP_NEWCUST_KEY : this.tab04.ASP_NEWCUST_KEY,
          REG_ID : userId,
          DIV_LIST : this.grid04Data.fliter(e => e.isSelected),
          FLAG : this.tab04.FLAG,
        };

      }

      await api.post(URLData, jsonData, {
        head : {
          SERVICE: HEADER_SERVICE,
          METHOD: HEADER_METHOD,
          TYPE: HEADER_TYPE,
          DATA_OBJECT : DATA_OBJECT,
        }
      })
      .then((response) => {
        if(typeof(response) == 'string'){
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
        }else{
          this.selectRtnQa();
          this.common_alert("정상처리 되었습니다.","done");
          if(this.activeTab == 0){
            //this.tab03 = Object.assign({}, this.tab01);
            this.tab03.QA_EVA_ID = this.tab01.QA_EVA_ID;
            this.tab03.QA_PG_AD_CN_YN = this.tab01.QA_PG_AD_CN_YN;
          }else if(this.activeTab == 2){
            this.selectRtnQaDiv();
            this.selectRtnQaDivCnt();
          }
        }
      })
      .catch((err) => {
        console.log(err);
        this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
      })
    },
    //평가표 미리보기 조회
    async selectRtnQaPreView(){
      if(this.isSelected && this.tab01.QA_EVA_ID != ''){
        let jsonData = {
          QA_EVA_ID : this.tab01.QA_EVA_ID,
          ASP_NEWCUST_KEY : this.grid01Search.ASP_NEWCUST_KEY,
        };

        let URLData = "api/phone/qa2/evl-qa-rst-manage/pre/all/list";
        let HEADER_SERVICE = "phone.qa2.evl-qa-rst-manage.pre.all";
        let HEADER_METHOD = "regist";
        let HEADER_TYPE = "BIZ_SERVICE";

        await api.post(URLData, jsonData, {
          head : {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
          }
        })
        .then((response) => {
          this.preViewQaQs = response.data.qaQsList;
          this.preViewVe = response.data.veList;
          this.showDialog('M310101P04');
        })
        .catch((err) => {
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
        })
      }else{
        alert("선택안됨");
      }
    },
    async selectRtnCnslType(cd, name){
      let jsonData = {
        SPST_CNSL_TYP_CD : cd,
        USE_YN : 'Y',
        ASP_NEWCUST_KEY : this.grid01Selected.ASP_NEWCUST_KEY,
      };

      let URLData = "api/setting/consulttype/manage/inqire";
      let HEADER_SERVICE = "setting.consulttype.manage";
      let HEADER_METHOD = "inqire";
      let HEADER_TYPE = "BIZ_SERVICE";

      await api.post(URLData, jsonData, {
        head : {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
          }
      }).then((response) => {
        let cdObj = {
          "CD_NM" : '전체',
          "CD" : ''
        };
        this.gridSelectBox[name] = response.data.DATA;
        this.gridSelectBox[name].splice(0,0, cdObj);
      })
      .catch((err) => {
        this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
      })
    },

    async selectRtnCnslHist(type, option){
      if(this.tab03.QA_TG_ST_DTTM == undefined || this.tab03.QA_TG_ST_DTTM == ''){
        this.common_alert("상담 일자를 선택해주세요.","error");
      }else if(this.tab03.QA_TG_EN_DTTM == undefined || this.tab03.QA_TG_EN_DTTM == ''){
        this.common_alert("상담 일자를 선택해주세요.","error");
      }else if(this.tab03.QA_TG_TY == undefined || this.tab03.QA_TG_TY == ''){
        this.common_alert("유형을 선택해주세요.","error");
      }else{
        let page = 1;
        let itemsPerPage = 10;
        if(type === "20"){
          itemsPerPage = this.grid03Paging.itemsPerPage;
          page = this.grid03Paging.page;
        }else if(type === "10"){
          itemsPerPage = this.grid02Paging.itemsPerPage;
          page = this.grid02Paging.page;
        }

        if(option === 'S'){
          page = 1;
        }

        let jsonData = this.tab03;

        let URLData = "api/phone/plan/qa-manage/cnsl/list";
        let HEADER_SERVICE = "phone.plan.qa-manage.cnsl";
        let HEADER_METHOD = "list";
        let HEADER_TYPE = "BIZ_SERVICE";

        await api.post(URLData, jsonData, {
          head : {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
            ROW_CNT : itemsPerPage,
            PAGES_CNT : page,
          }
        }).then((response) => {
          if(type === "10"){
            this.grid02Data = response.data.DATA;
          }else if(type === "20"){
            this.grid03Data = response.data.DATA;
          }
          if(response.data.DATA.length > 0){
            this.tab03.QA_TG_CNT = response.data.DATA[0].TWB_PAGING_TOT_COUNT;
            if(type === "10"){
              this.grid02Paging.totalCount = parseInt(response.data.DATA[0].TWB_PAGING_TOT_COUNT);
            }else if(type === "20"){
              this.grid03Paging.totalCount = parseInt(response.data.DATA[0].TWB_PAGING_TOT_COUNT);
            }
          }else{
            this.tab03.QA_TG_CNT = 0;
            this.grid02Paging.totalCount = 0;
            this.grid03Paging.totalCount = 0;
          }

        }).catch((err) => {
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
        });

      }
    },
    async selectRtnQaDivCnt(){
      let jsonData = {
        QA_ID : this.grid01Selected.QA_ID,
        ASP_NEWCUST_KEY : this.grid01Selected.ASP_NEWCUST_KEY,
      };

      let URLData = "api/phone/plan/qa-manage/div/cnt/list";
      let HEADER_SERVICE = "phone.plan.qa-manage.div.cnt";
      let HEADER_METHOD = "list";
      let HEADER_TYPE = "BIZ_SERVICE";

      await api.post(URLData, jsonData, {
        head : {
          SERVICE: HEADER_SERVICE,
          METHOD: HEADER_METHOD,
          TYPE: HEADER_TYPE,
        }
      }).then((response) => {
        if(typeof(response) != 'string'){
          if(response.data.DATA.length > 0){
            this.tab04 = response.data.DATA[0];
          }else{
            this.tab04 = {
              TOT_CNT : "0",
              NO_DIV_CNT : "0",
              DIV_CNT : "0",
              CP_FS_QA_CNT : "0",
              CP_SC_QA_CNT : "0",
              CP_FN_QA_CNT : "0",
              CP_QA_CNT : "0",
            };
          }
        }else{

        }
      }).catch((err) => {
        this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
      });
    },
    async selectRtnQaDiv(){
      let jsonData = {
        QA_ID : this.grid01Selected.QA_ID,
        ASP_NEWCUST_KEY : this.grid01Selected.ASP_NEWCUST_KEY,
      };

      let URLData = "api/phone/plan/qa-manage/div/list";
      let HEADER_SERVICE = "phone.plan.qa-manage.div";
      let HEADER_METHOD = "list";
      let HEADER_TYPE = "BIZ_SERVICE";

      await api.post(URLData, jsonData, {
        head : {
          SERVICE: HEADER_SERVICE,
          METHOD: HEADER_METHOD,
          TYPE: HEADER_TYPE,
        }
      }).then((response) => {
        let data = response.data.DATA;
        this.grid04Data = [];
        for(let i = 0; i < data.length; i++){
          this.grid04Data.push(
            {
              ROW_NUMBER : i+1,
              DIV_USER_ID : data[i].DIV_USER_ID,
              DIV_USER_NM : data[i].DIV_USER_NM,
              DIV_CNT : data[i].DIV_CNT,
              CP_QA_CNT : data[i].CP_QA_CNT,
              WAIT_QA_CNT : data[i].WAIT_QA_CNT,
              CP_FS_QA_CNT : data[i].CP_FS_QA_CNT,
              CP_SC_QA_CNT : data[i].CP_SC_QA_CNT,
              CP_FN_QA_CNT : data[i].CP_FN_QA_CNT,
              QA_DATA : 0,
            }
          )
        }

      }).catch((err) => {
        this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
      });
    },
    async processRtnQaDiv(){

      let QA_LIST = this.grid04Data.filter(e => e.QA_DATA > 0);

      if(Number(this.tab04.NO_DIV_CNT) == 0){
        this.common_alert("더 이상 분배할 건수가 없습니다.","noti");
        return;
      }

      let qaCnt = 0;
      QA_LIST.map(e => qaCnt += Number(e.QA_DATA));

      if(Number(this.tab04.NO_DIV_CNT) < qaCnt){
        this.common_alert("미분배건수보다 분배건수가 많습니다.","noti");
        return;
      }

      if(QA_LIST.length > 0){
        let jsonData = {
          QA_ID : this.grid01Selected.QA_ID,
          ASP_NEWCUST_KEY : this.grid01Selected.ASP_NEWCUST_KEY,
          QA_LIST : QA_LIST,
          DIV_FLAG : 'D',
        };

        let URLData = "api/phone/plan/qa-manage/div/regist";
        let HEADER_SERVICE = "phone.plan.qa-manage.div";
        let HEADER_METHOD = "regist";
        let HEADER_TYPE = "BIZ_SERVICE";
        let DATA_OBJECT = "QA_LIST"

        await api.post(URLData, jsonData,{
          head : {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
            DATA_OBJECT : DATA_OBJECT,
          }
        }).then((response) => {
          if(typeof(response) != 'string'){
            this.common_alert("정상처리 되었습니다.","done");
            this.selectRtnQaDiv();
            this.selectRtnQaDivCnt();
            this.selectRtnQa();
          }else{
            this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
          }
        }).catch((err) => {
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
        });
      }else{
        this.common_alert("추가/회수할 건수가 없습니다. ","noti")
      }
    },
    async processRtnQaDivRecall(){


      let QA_LIST = this.grid04Data.filter(e => e.QA_DATA > 0);

      let validCode = ""
      QA_LIST.map(e => {
        if(Number(e.WAIT_QA_CNT) == 0){
          validCode = 'NO_WAIT_CNT';
        }else if(Number(e.WAIT_QA_CNT) < Number(e.QA_DATA)){
          validCode = "TO_MANY"
        }
      });

      if(validCode == 'NO_WAIT_CNT'){
        this.common_alert("회수할 미시도 건수가 없는 항목은 회수할 수 없습니다.","noti");
        return;
      }else if(validCode == 'TO_MANY'){
        this.common_alert("미시도 건수보다 회수할 건수가 많습니다.","noti");
        return;
      }


      if(QA_LIST.length > 0){
        let jsonData = {
          QA_ID : this.grid01Selected.QA_ID,
          ASP_NEWCUST_KEY : this.grid01Selected.ASP_NEWCUST_KEY,
          QA_LIST : QA_LIST,
          DIV_FLAG : 'R',
        };

        let URLData = "api/phone/plan/qa-manage/div/regist";
        let HEADER_SERVICE = "phone.plan.qa-manage.div";
        let HEADER_METHOD = "regist";
        let HEADER_TYPE = "BIZ_SERVICE";
        let DATA_OBJECT = "QA_LIST"

        await api.post(URLData, jsonData,{
          head : {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
            DATA_OBJECT : DATA_OBJECT,
          }
        }).then((response) => {
          if(typeof(response) != 'string'){
            this.common_alert("정상처리 되었습니다.","done");
            this.selectRtnQaDiv();
            this.selectRtnQaDivCnt();
            this.selectRtnQa();
          }else{
            this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
          }
        }).catch((err) => {
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
        });
      }else{
        this.common_alert("추가/회수할 건수가 없습니다. ","noti")
      }
    },

    /*===========================데이터 조회 끝============================*/
    insertRtnQaForm(){
      if(this.grid01Search.ASP_NEWCUST_KEY == undefined || this.grid01Search.ASP_NEWCUST_KEY == ''){
        this.common_alert("회사구분을 선택해주세요.","error");
      }else{
        this.mFlag = false;
        this.showDialog('M310201P01');
      }
    },
    updateRtnQaForm(){
      this.mFlag = true;
      this.showDialog('M310201P01');
    },
    insertRtnQaCallBack(){
      this.selectRtnQa();
      this.grid01Selected = {};
      this.submitDialog('M310201P01');
    },
    //기타
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    showDialog(type) {
      this[`dialog${type}`] = true
    },
    hideDialog(type) {
      this[`dialog${type}`] = false
    },
    submitDialog(type) {
      this[`dialog${type}`] = false
    },
    setGrid01Select(item){
      this.grid01Selected = Object.assign({}, item);
      this.tab01 = Object.assign({}, item);
      this.tab02 = Object.assign({}, item);
      this.tab03 = Object.assign({}, item);
      this.selectRtnEvlPaper();
      this.selectRtnCnslType("*", "QA_CNSL_TYP_CD");
      this.selectRtnCnslType(this.tab03.QA_CNSL_TYP_CD, "QA_CNSL_TYP_CD2");
      this.selectRtnCnslType(this.tab03.QA_CNSL_TYP_CD2, "QA_CNSL_TYP_CD3");
      this.selectRtnCnslType(this.tab03.QA_CNSL_TYP_CD3, "QA_CNSL_TYP_CD4");
      this.grid02Data = [];
      this.grid03Data = [];
      this.grid04Data = [];
      this.grid04Selected = [];
      //this.selectRtnCnslHist(this.tab03.QA_TG_TY, 'S');
      this.selectRtnQaDiv();
      this.selectRtnQaDivCnt();

      let qa_ta_st = this.grid01Selected.QA_TA_ST;

      if(qa_ta_st >= 45){
        this.QA_TA_ST = ['25','35','45'];
      }else if(qa_ta_st >= 35){
        this.QA_TA_ST = ['25','35',''];
      }else if(qa_ta_st >= 25){
        this.QA_TA_ST = ['25','',''];
      }else{
        this.QA_TA_ST = ['','',''];
      }

    },
    selectEvlPaper(item){
      this.tab01.QA_EVA_ID = item[0].QA_EVA_ID;
      this.tab01.QA_EVA_RE_YN = item[0].QA_EVA_RE_YN;
      this.submitDialog('M310201P02');
    },
    //균등분배
    setQaDivAvg(){
      if(this.grid04Selected.length > 0){
        this.setQaDivReSet();
        let length = this.grid04Selected.length;
        let qaCnt = this.tab04.NO_DIV_CNT;

        let avgCnt = Math.floor(qaCnt / length);
        let remainCnt = qaCnt % length;

        for(let i = 0; i < this.grid04Selected.length; i++){
          this.grid04Selected[i].QA_DATA = avgCnt;
        }

        for(let i = 0; i < remainCnt; i++){
          this.grid04Selected[i].QA_DATA += 1;
        }

        this.grid04Selected = [];
      }else{
        this.common_alert("균등분배할 대상을 선택해주세요.","noti");
      }
    },
    //전체 회수
    setQaDivRecallAll(){
      this.setQaDivReSet();
      this.grid04Data.map(e => {
        if(e.DIV_CNT > 0){
          e.QA_DATA = e.WAIT_QA_CNT;
        }
      });
    },
    //초기화
    setQaDivReSet(){
      this.grid04Data.map(e => e.QA_DATA = 0);
    },
    changeEvlPaper(value){
      this.evlPaperSelectBox.map(e => {
        if(e.QA_EVA_ID == value){
          this.tab01.QA_EVA_RE_YN = e.QA_EVA_RE_YN;
        }
      })
    },
    changeQaTgTy(){
      if(this.tab03.QA_TG_TY == '10'){
        this.tab03.QA_TG_CNT = this.grid02Paging.totalCount;
      }else if(this.tab03.QA_TG_TY == '20'){
        this.tab03.QA_TG_CNT = this.grid03Paging.totalCount;
      }
    },
    isActiveRow(item){
      return item.QA_ID == this.grid01Selected.QA_ID? 'active':'';
    },
  },
  computed: {
    searchDateRangeText(){
      var newStartDate = this.grid01Search.START_DATE;
      var newEndDate = this.grid01Search.END_DATE;
      return newStartDate + " ~ " + newEndDate;
    },
    cnslDateRangeText() {
      var newStartDate = this.tab03.QA_TG_ST_DTTM;
      var newEndDate = this.tab03.QA_TG_EN_DTTM;
      return newStartDate + " ~ " + newEndDate;
    },
    qaFsRangeText(){
      var newStartDate = this.tab02.QA_FS_ST_DTTM;
      var newEndDate = this.tab02.QA_FS_EN_DTTM;
      return newStartDate + " ~ " + newEndDate;
    },
    qaScRangeText(){
      var newStartDate = this.tab02.QA_SC_ST_DTTM;
      var newEndDate = this.tab02.QA_SC_EN_DTTM;
      return newStartDate + " ~ " + newEndDate;
    },
    qaFnRangeText(){
      var newStartDate = this.tab02.QA_FN_ST_DTTM;
      var newEndDate = this.tab02.QA_FN_EN_DTTM;
      return newStartDate + " ~ " + newEndDate;
    },
    isSelected(){
      let isSelected = false;
      if(this.grid01Selected.QA_ID != null && this.grid01Selected.QA_ID != ''){
        isSelected = true;
      }
      return isSelected;
    },
    isModify(){
      let isModify = false;

      if(this.grid01Selected.QA_ID != null && this.grid01Selected.QA_ID != ''){
        if(this.grid01Selected.QA_TA_ST == '10'){
          isModify = true;
        }
      }
      return isModify;
    },
    toDay(){
      return new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10);
    },
    tab03Modify(){
      return (!this.isSelected || (this.tab03.QA_TG_YN == 'Y' && this.tab03.QA_PG_AD_CN_YN != '10'));
    }
  },
};
</script>

<style>

</style>
