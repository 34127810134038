<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ headerTitle }}
      <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div>
         <template v-if="p01">

        <div class="gray-box">
          <div class="form-inp sm ml-2">
            <v-select
              :items="PLT008"
              item-text="text"
              item-value="value"
              outlined
              hide-details
              label="유형타입"
              placeholder="선택하세요"
              v-model="grid01.QA_TY"
            >
            <template v-slot:label>
                유형타입
              <v-icon color="#EF9191">mdi-circle-small</v-icon>
            </template>
            </v-select>
          </div>
          <v-text-field
            class="form-inp ml-2"
            name="name" label="QA평가명" id="id"
            outlined hide-details=""
            v-model.trim="grid01.QA_NM"
            error-count="50"
            >
            <template v-slot:label>
                QA평가명
              <v-icon color="#EF9191">mdi-circle-small</v-icon>
            </template>
          </v-text-field>
          <div class="mt-3">
            <v-menu
              v-model="popDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              content-class="calendar-modal"
              color="#FFF"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  outlined
                  hide-details
                  class="form-inp icon-calendar ml-2"
                  append-icon="svg-calendar"
                  label="전체평가기간"
                ></v-text-field>
              </template>
              <div class="datepicker-range">
                <v-date-picker
                  v-model="grid01.QA_ST_DTTM"
                  no-title
                  dark
                  color="#F7AFAE"
                  @change="startDate"
                  locale="ko-KR"
                  :first-day-of-week="1"
                  :day-format="mixin_getDate"
                ></v-date-picker>
                <v-date-picker
                  v-model="grid01.QA_EN_DTTM"
                  dark
                  color="#F7AFAE"
                  no-title
                  show-current="false"
                  :min="grid01.QA_ST_DTTM"
                  @change="endDate"
                  locale="ko-KR"
                  :first-day-of-week="1"
                  :day-format="mixin_getDate"
                ></v-date-picker>
              </div>
              <div class="text-center pt-3 pb-3">
                <v-btn
                  outlined
                  class="btn-default"
                  @click="popDate = false"
                  >확인</v-btn
                >
              </div>
            </v-menu>
          </div>
        </div>
        <div class="text-right mt-3">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined class="btn-point ml-2" @click="common_confirm('저장하시겠습니까?',insertRtnQs,'',null,'','chk')">저장</v-btn>
        </div>
      </template>
      <template v-if="p02">
        <div class="d-flex gray-box">
          <div>
            <div class="form-inp sm">
              <v-select
                :items="PLT008"
                item-text="text"
                item-value="value"
                outlined
                hide-details
                label="유형타입"
                placeholder="선택하세요"
                v-model="evlPaperSearch.QA_EVA_TY"
                @change="selectRtnQaEva()"
              ></v-select>
            </div>
            <div class="form-inp sm ml-2">
              <v-select
                :items="PLT012"
                item-text="text"
                item-value="value"
                outlined
                hide-details
                label="사용여부"
                placeholder="선택하세요"
                v-model="evlPaperSearch.USE_YN"
              ></v-select>
            </div>
            <v-text-field
              class="form-inp sm ml-2"
              name="name" label="평가표명" outlined hide-details
              v-model="evlPaperSearch.QA_EVA_NM"
              @keyup.enter="selectRtnQaEva()"
              >
            </v-text-field>
          </div>
          <div class="ml-auto align-self-center">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined small class="btn-etc2 ml-3" @click="selectRtnQaEva()">조회</v-btn>
          </div>
        </div>
        <div class="table-form mt-2">
          <v-data-table
            dense
            :headers="evlPaperHeader"
            :items="evlPaperData"
            item-key="QA_EVA_ID"
            hide-default-footer
            class="grid-default text-truncate"
            show-select
            single-select
            height="200"
            no-data-text="검색된 결과가 없습니다."
            :page.sync="evlPaperPaging.page"
            :items-per-page="30"
            @page-count="evlPaperPaging.pageCount = $event"
            v-model="selectedEvlPaper"
            fixed-header
          >
          </v-data-table>
          <div class="grid-paging text-center pt-2">
            <v-pagination
              v-model="evlPaperPaging.page"
              :length="evlPaperPaging.pageCount"
              :total-visible="evlPaperPaging.totalVisible"
              next-icon="svg-paging-next"
              prev-icon="svg-paging-prev"
            ></v-pagination>
          </div>
        </div>
        <div class="text-right mt-3">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnChoice')" outlined class="btn-default" @click="selectEvlPaper()">선택</v-btn>

        </div>
      </template>
      </div>

    </div>
  </div>

</template>

<script>
import api from '@/store/apiUtil.js'
import {mixin} from '@/mixin/mixin.js'
export default {
  name: "MENU_M310201P01", //name은 'MENU_' + 파일명 조합
  mixins : [mixin],
  props: {
    isModify: {
      type: Boolean,
      default: false,
    },
    p01: {
      type: Boolean,
      default: false,
    },
    p02: {
      type: Boolean,
      default: false,
    },
    p03: {
      type: Boolean,
      default: false,
    },
    headerTitle: {
      type: String,
      default: "제목",
    },
    PLT008:[],
    PLT011:[],
    PLT012:[],
    grid01Selected:{},
    ASP_NEWCUST_KEY: {
      type: String,
    }
  },
  data: () => ({
    evlPaperHeader : [
        {
        text: 'No',
        align: 'center',
        value: 'QA_EVA_ID',
        width: '50px',
        sortable: true,
      },
      { text: '유형타입', value: 'QA_EVA_TY_NM', align: 'center', sortable: true, },
      { text: '평가표 명', value: 'QA_EVA_NM',align: 'center', sortable: true, },
      { text: '설명', value: 'QA_EVA_RT', align: 'center',sortable: true, },
      { text: '2차', value: 'QA_EVA_CN_TWO', align: 'center',sortable: true, },
      { text: '3차', value: 'QA_EVA_CN_THREE', align: 'center',sortable: true, },
      { text: '총점', value: 'QA_EVA_SUM', align: 'center',sortable: true, },
      { text: '사용여부', value: 'QA_EVA_YN', align: 'center',sortable: true, },

    ],
    grid01:{
      QA_ID : "",
      QA_NM : "",
      QA_TY : "",
      QA_ST_DTTM : "",
      QA_EN_DTTM : "",
    },
    evlPaperData: [],
    evlPaperSearch:{},
    selectedEvlPaper:{},
    evlPaperPaging:{
      page: 1,
      pageCount: 1,
      itemsPerPage: 5,
      totalVisible: 5,
    },
    valid : true,
    footerHideTitle: "닫기",
    popDate: false,
    dropItems: [{ title: "option1" }],
    dates: [
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    ],
  }),
  created(){
    if(this.p01){
      if(this.isModify){
        this.grid01.QA_ID = this.grid01Selected.QA_ID;
        this.grid01.QA_NM = this.grid01Selected.QA_NM;
        this.grid01.QA_TY = this.grid01Selected.QA_TY;
        this.grid01.QA_ST_DTTM = this.grid01Selected.QA_ST_DTTM;
        this.grid01.QA_EN_DTTM = this.grid01Selected.QA_EN_DTTM;
      }else{
        this.grid01.QA_ST_DTTM = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
        this.grid01.QA_EN_DTTM = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
      }
    }

    if(this.p02){
      this.selectRtnQaEva();
    }
  },
  methods: {
    startDate(e) {
      this.grid01.QA_ST_DTTM = e;
    },
    endDate(e) {
      this.grid01.QA_EN_DTTM = e;
    },
    showDialog(type) {
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    // 평가 기획 등록
    async insertRtnQs(){
      if(this.grid01.QA_TY == undefined || this.grid01.QA_TY == ''){
        this.common_alert("유형을 선택해주세요.","error");
      }else if(this.grid01.QA_NM == undefined || this.grid01.QA_NM == ''){
        this.common_alert("평가명을 입력해주세요.","error");
      }else{
        let jsonData = {
          'ASP_NEWCUST_KEY' : this.ASP_NEWCUST_KEY,
          'QA_ID' : this.grid01.QA_ID,
          'QA_NM' : this.grid01.QA_NM,
          'QA_TY' : this.grid01.QA_TY,
          'QA_ST_DTTM' : this.grid01.QA_ST_DTTM,
          'QA_EN_DTTM' : this.grid01.QA_EN_DTTM,
          'REG_NM' : this.$store.getters['userStore/GE_USER_ROLE'].userName,
          'REG_ID' : this.$store.getters['userStore/GE_USER_ROLE'].userId,
        }

        let URLData = "api/phone/plan/qa-manage/regist";
        let HEADER_SERVICE = "phone.plan.qa-manage";
        let HEADER_METHOD = "regist";
        let HEADER_TYPE = "BIZ_SERVICE";

        await api.post(URLData, jsonData, {
          head : {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
          }
        })
        .then((response) => {
          this.successAlert();
          this.$emit('submit');
        })
        .catch((err) => {
          this.errorAlert();
        })
      }
    },
    /* 평가표 리스트 조회 */
    async selectRtnQaEva(){

      let jsonData = {
        'ASP_NEWCUST_KEY' : this.ASP_NEWCUST_KEY,
        'QA_EVA_TY': this.evlPaperSearch.QA_EVA_TY,
        'QA_EVA_YN': this.evlPaperSearch.QA_EVA_YN,
        'QA_EVA_NM': this.evlPaperSearch.QA_EVA_NM,
      }

      let URLData = "api/phone/plan/qa-manage/evl-paper/list";
      let HEADER_SERVICE = "phone.plan.qa-manage.evl-paper";
      let HEADER_METHOD = "list";
      let HEADER_TYPE = "BIZ_SERVICE";

      await api.post(URLData,jsonData,{
        head : {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
        }
      })
      .then((response) => {
        this.evlPaperData = response.data.DATA;
      })
      .catch((err) => {
        this.errorAlert();
      })
    },
    selectEvlPaper(){
      if(this.selectedEvlPaper.length > 0){
        this.$emit('selected', this.selectedEvlPaper);
      }else{
        this.warningAlert('평가표를 선택해주세요.')
      }
    },
    warningAlert(text){
      let alertText = '시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.';
      if(text != null && text != ''){
        alertText = text;
      }
      this.common_alert(alertText, 'error');
    },
    successAlert(){
      this.common_alert('정상처리 되었습니다.', 'done');
    },
    errorAlert(){
      this.common_alert('시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.', 'error');
    },
  },
  computed: {
    dateRangeText() {
      var newStartDate = this.grid01.QA_ST_DTTM;
      var newEndDate = this.grid01.QA_EN_DTTM;
      return newStartDate + " ~ " + newEndDate;
    },
  },
};
</script>

<style></style>
